import React, { useContext } from 'react'
import ColorContext from '../context/ColorContext'
import { spacing, typography } from '../theme/themeAlopex'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Marketing from '../components/Services/Marketing'
import Design from '../components/Services/Design'
import Digital from '../components/Services/Digital'
import { Box } from '@chakra-ui/react'
import { H3, H6 } from '../theme/Headings'

export const ServiceSlider = () => {
  const { color } = useContext(ColorContext)
  // Slider settings
  const sliderSettings = {
    arrows: true,
    centerMode: false,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 2.2,
    speed: 100,
    variableWidth: false,
    adaptiveHeight: false,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.05
        }
      }
    ]
  }

  return (
    <Box
      sx={{
        '.svcColumn': {
          position: 'relative',
          px: '1rem',
          '.svcColumnHead': {
            textAlign: 'center'
          }
        },
        '.svcBox': {
          marginBottom: '32px',
          padding: '42px 25px',
          boxShadow: 'rgb(255, 255, 255) -3px -3px 8px 1px, rgb(167, 179, 185) 3px 3px 8px 2px',
          background: 'linear-gradient(135deg, #f1f1f1, #e5e5e5)',
          borderRadius: '24px',
          ul: {
            listStyle: 'none',
            margin: 0,
            mb: '20px',
            padding: 0,
            pl: '20px',
            pt: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            position: 'relative',
            li: {
              color: 'rgba(0, 0, 0, 0.1)',
              background: `-webkit-linear-gradient(right, ${color.bravo}, ${color.alpha})`,
              WebkitBackgroundClip: 'text',
              display: 'inline-block',
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                right: 'calc(100% + 4px)',
                transform: 'translateY(-50%)',
                width: '14px',
                height: '3px',
                borderTop: `1px dotted ${color.alpha}`
              },
              a: {
                display: 'block',
                textDecoration: 'none'
              }
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              height: 'calc(100% - 12px)',
              borderLeft: `1px dotted ${color.alpha}`
            }
          }
        },
        '.slick-slider': {
          '.slick-prev, .slick-next': {
            width: '40px',
            height: '40px',
            boxShadow: '0 5px 12px rgba(0, 0, 0, 0.25)',
            borderRadius: '999px',
            '&:before, &:after': {
              color: color.alpha,
              opacity: 1
            },
            '&.slick-disabled': {
              opacity: 0,
              pointerEvents: 'none'
            }
          },
          '.slick-prev': {
            left: '-8px',
            zIndex: 10,
            '&::before': {
              content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 80 80'%3E%3Ccircle cx='40' cy='40' r='24.78' fill='%23fff' stroke-width='0'/%3E%3Cpath d='M40,0c22.09,0,40,17.91,40,40s-17.91,40-40,40S0,62.09,0,40,17.91,0,40,0ZM42.82,55.3c.48.47,1.11.7,1.73.7.66,0,1.31-.26,1.8-.76.96-.99.93-2.58-.06-3.54l-12.13-11.7,12.13-11.7c.99-.96,1.02-2.54.06-3.54-.96-.99-2.54-1.02-3.54-.06l-15.87,15.3,15.87,15.3Z' fill='${color.alpha.replace('#', '%23')}' stroke-width='0'/%3E%3C/svg%3E")`
            }
          },
          '.slick-next': {
            right: '10px',
            '&::before': {
              content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 80 80'%3E%3Ccircle cx='40' cy='40' r='24.78' fill='%23fff' stroke-width='0'/%3E%3Cpath d='M40,0C17.91,0,0,17.91,0,40s17.91,40,40,40,40-17.91,40-40S62.09,0,40,0ZM37.18,55.3c-.48.47-1.11.7-1.73.7-.66,0-1.31-.26-1.8-.76-.96-.99-.93-2.58.06-3.54l12.13-11.7-12.13-11.7c-.99-.96-1.02-2.54-.06-3.54.96-.99,2.54-1.02,3.54-.06l15.87,15.3-15.87,15.3Z' fill='${color.alpha.replace('#', '%23')}' stroke-width='0'/%3E%3C/svg%3E")`
            }
          },
          '.slick-list': {
            '.slick-track': {
              display: 'flex',
              '.slick-slide': {
                height: 'inherit',
                '> div': {
                  height: '100%',
                  '.inetCard': {
                    height: '100%'
                  }
                }
              }
            }
          }
        },
        '@media only screen and (min-width: 640px)': {
          '.slick-slider': {
            '.slick-prev, .slick-next': {
              width: '60px',
              height: '60px'
            },
            '.slick-prev': {
              left: 0
            },
            '.slick-next': {
              right: '20px'
            }
          }
        }
      }}
    >
      <Slider {...sliderSettings}>
        <Box className="svcColumn">
          <Box className="svcColumnHead">
            <Box maxHeight="100px" maxWidth="100px" mb={spacing.icon} mx="auto">
              <Marketing />
            </Box>
            <Box maxWidth="70%" mx="auto" mb="50px">
              <H3 variant="figtree">Marketing</H3>
              <p>The efforts put forth to reach the goals of your organization.</p>
            </Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Strategy</H6>
            <ul>
              <li><a href="/services/seo-services">Premium SEO Services</a></li>
              <li><a href="/services/branding">Premium Brand Design</a></li>
              <li><a href="/services/sem-services">Premium SEM Services</a></li>
            </ul>
            <Box as="p" mb="0">You are an expert in your field, let us lend you our expertise in crafting effective marketing strategy.</Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Content</H6>
            <ul>
              <li><a href="/services/print">Print Design</a></li>
              <li><a href="/services/custom-website-developers">Custom Websites</a></li>
            </ul>
            <Box as="p" mb="0">Our team of creatives produces beautiful and valuable video, graphics, and writing everyday.</Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Design</H6>
            <ul>
              <li><a href="/services/seo-services">Premium SEO Services</a></li>
              <li><a href="/services/custom-website-developers">Custom Websites</a></li>
            </ul>
            <Box as="p" mb="0">Alopex is unyielding in our pursuit of excellence in graphic design across mediums.</Box>
          </Box>
        </Box>

        <Box className="svcColumn">
          <Box className="svcColumnHead">
            <Box maxHeight="100px" maxWidth="170px" mb={spacing.elements} mt="-30px" mx="auto">
              <Design />
            </Box>
            <Box maxWidth="85%" mx="auto" mb={spacing.elements}>
              <H3 variant="figtree">Experiences</H3>
              <p>Award-winning expressions of your brand in digital and physical spaces.</p>
            </Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Websites</H6>
            <ul>
              <li><a href="/services/custom-website-developers">Custom Websites</a></li>
              <li><a href="/services/branding">Premium Brand Design</a></li>
              <li><a href="/services/print">Print Design</a></li>
            </ul>
            <Box as="p" mb="0">From research, to design, to testing, our in-house team of designers and developers craft exceptional websites.</Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Apps</H6>
            <ul>
              <li><a href="/services/ui-ux-designers">UI/UX Design</a></li>
              <li><a href="/services/software-development">Software and SaaS Development</a></li>
              <li><a href="/services/api-integration-services">API Integration</a></li>
            </ul>
            <Box as="p" mb="0">Whether you need to discuss the feasibilty of a concept or are looking for a team of programmers, we have you covered.</Box>
          </Box>
        </Box>

        <Box className="svcColumn">
          <Box className="svcColumnHead">
            <Box maxHeight="100px" maxWidth="150px" mb="40px" mt="-20px" mx="auto">
              <Digital />
            </Box>
            <Box maxWidth="85%" mx="auto" mb={spacing.elements}>
              <H3 variant="figtree">Technical</H3>
              <p>Alopex&rsquo;s technical offerings that allow us to provide extended services.</p>
            </Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Software</H6>
            <ul>
              <li><a href="/services/ui-ux-designers">UI/UX Design</a></li>
              <li><a href="/services/software-development">Software and SaaS Development</a></li>
              <li><a href="/services/api-integration-services">API Integration</a></li>
            </ul>
            <Box as="p" mb="0">A department of Alopex devoted to building and maintaining software and integrations for enterprises.</Box>
          </Box>
          <Box className="svcBox">
            <H6 fontFamily={typography.fonts.body} color={color.alpha} textTransform="uppercase" fontWeight="700" letterSpacing="1.2px" fontSize="1rem">Digital Advertising</H6>
            <ul>
              <li><a href="/services/seo-services">Premium SEO Services</a></li>
              <li><a href="/services/custom-website-developers">Custom Websites</a></li>
            </ul>
            <Box as="p" mb="0">Alopex yields industry-breaking results for our clients by leveraging our programmers and creatives on every campaign.</Box>
          </Box>
        </Box>
      </Slider>
    </Box>
  )
}